@import './variables/colors';
@import './variables/sizes';
@import './base';
@import '../../index';
@import './theme';
@import './media';

#root {
  transition: blur ease-in-out .3s;
}

