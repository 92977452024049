.button {
  width: 100%;
  height: 44px;
  //margin-top: 8px;
  padding: 2px 16px;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  background-color: #eaeaea;
  font-size: 16px;
  line-height: 24px;
  transition: background-color .17s ease, color .17s ease;
  &__content {
    margin: 0 auto;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    background-color: #dcdcdc;
  }
  &--small {
    font-size: 14px;
    max-height: 36px;
    max-width: 100px;
  }
  &--medium {
    font-size: 14px;
    max-height: 36px;
    max-width: 130px;
  }
  &--danger {
    background-color: #5bb285;
    &:hover {
      background-color: #55ac7f;
    }
  }
  &--transparent {
    background-color: transparent;
    &:hover {
      .button__content {
        text-decoration: underline;
      }
      background-color: transparent;
    }
  }
}
