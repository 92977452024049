.tile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-radius: 17px;
  //background: linear-gradient(145deg, #cfcfcf, #f6f6f6);
  //box-shadow:  5px 5px 12px #c6c6c6,
  //-5px -5px 12px #ffffff;

  border-radius: 7px;
  background: #fafafa;
  box-shadow:  5px 5px 10px #e1e1e1,
  -5px -5px 10px #e6e6e6;

  width: $tile-normal - 8;
  height: $tile-normal - 8;
  margin: 4px;

  transition: left .3s ease-in-out, top .3s ease-in-out;
  &--hide {
    opacity: 0;
  }
  &__text {
    font-size: 40px;
    color: #757575;
  }
}
