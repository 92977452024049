.info {
  font-family: "Nunito Semi Bold", sans-serif;
  &__wrapper {
    margin-top: 40px;
    margin-bottom: 50px;
    text-align: center;
  }
  &__time {
    color: #666666;
    font-size: 20px;
    letter-spacing: 2px;
    &-divider {
      opacity: 1;
      //transition: opacity .1s ease-in-out;
      &--invisible {
       opacity: 0;
     }
    }
  }
  &__steps {
    color: #666666;
    font-size: 20px;
  }
}
