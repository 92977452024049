.dialog {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  transition: visibility 0s linear, opacity 0.5s linear;
  opacity: 0;
  visibility: hidden;
  display: none;
  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    width: 100vw;
    height: 100vh;
  }
  &__wrap {
    position: relative;
    z-index: 1;
    display: block;
    box-sizing: border-box;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    background: #f4f4f4;
    box-shadow: 0 0 0 1px rgba(240,240,240,.6), 0 2px 10px 0 rgba(240,240,240,.2);
    opacity: 0;
    overflow: hidden;
    transition: all .5s;
    &--dark {
      background: #202225;
    }
    &--small {
      width: 350px;
      max-height: 24rem;
    }
    &--medium {
      width: 540px;
      height: auto;
    }
    &--big {
      width: 640px;
      height: auto;
    }
  }
  &__content {
    padding: 20px;
    &--top {
      padding: 20px 0 0 0;
    }
  }
  &__image {
    max-height: 200px;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
    padding: 0px 8px 0px 12px;
    outline: none;
  }
  &__title {
    font-family: 'Nunito Regular', sans-serif;
    color: #333333;
    text-transform: uppercase;
    font-size: 16px;
    margin: 0;
    padding: 20px;
    word-wrap: break-word;
  }
  &__subtitle {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 12px 0 20px;
    min-height: 64px;
    height: 100%;
    color: #757575;
    font-weight: 300;
  }
  &__footer-text {
    display: flex;
    flex: 1 1 auto;
    padding: 0 12px 0 20px;
    min-height: 64px;
    height: 100%;
    color: #757575;
    font-weight: 300;
  }
  &__prompt {
    background-color: #e6e6e6;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
  &--is-open {
    transition-delay:0s;
    opacity: 1;
    visibility: visible;
    display: block;
    .dialog__wrap {
      opacity: 1;
    }
    #root {
      background-color: #e6554f;
      height: 10px;
      filter: blur(6px) !important;
    }
  }
}
