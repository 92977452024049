.table {
  position: absolute;
  margin: auto;
  left: -3px;
  right: 0;
  text-align: center;
  //z-index: -1;
  display: flex;
  justify-content: center;
  //border-radius: 26px;
  //background: linear-gradient(145deg, #dcdcdc, #ffffff);
  //box-shadow:  32px 32px 64px #e3e3e3,
  //-16px -16px 64px #ffffff;
  border-radius: 7px;
  background: #e6e6e6;
  box-shadow: 7px 7px 16px #d6d6d6,
  -7px -7px 16px #f6f6f6;

  table {
    border-collapse: collapse;
  }

  td, th {
    //border: 3px solid #ffffff;
    text-align: left;
    padding: 8px;
  }

  td {
    width: 100px;
    height: 100px;
    text-align: center;
  }
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 2.5px;
}
